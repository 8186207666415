<!-- src/components/PricingComponent.vue -->
<template>
    <div class="container mx-auto p-6">
      <h1 class="text-3xl font-bold mb-4 text-center">Pricing Plans</h1>
      <p class="text-center mb-6">
        Our timekeeping application is designed to help small businesses comply with EU timekeeping regulations, including GDPR compliance. Choose a plan that fits your needs. - These plans are based on your level of support on Patreon.
      </p>
  
      <!-- Free Tier -->
      <div class="pricing-card bg-white shadow-md rounded-lg p-4 mb-6">
        <h2 class="text-2xl font-bold mb-2">Free Tier</h2>
        <p class="mb-4">FREE</p>
        <p>Ideal for freelancers, consultants, or small business owners who want to try our solution.</p>
        <p>Limit of 2 projects</p>
      </div>
  
      <!-- Basic Plan -->
      <div class="pricing-card bg-white shadow-md rounded-lg p-4 mb-6">
        <h2 class="text-2xl font-bold mb-2">Basic Plan</h2>
        <p class="mb-4">3 USD per user per month.</p>
        <p>You like Timekeeping App - and would like to help us in keeping our expenses down -</p>
        <p>and possibly also being able to buy a cup of tea sometimes..</p>
        <p>Limit of 2 projects</p>
      </div>
  
      <!-- Standard Plan -->
      <div class="pricing-card bg-white shadow-md rounded-lg p-4 mb-6">
        <h2 class="text-2xl font-bold mb-2">Standard Plan</h2>
        <p class="mb-4">6 USD per user per month.</p>
        <p>You Enjoy using Timekeeping App, and want to help us in making improvements to the systems (making it harder, better, faster, stronger).</p>
        <p>You can also come with ideas for improvements to the app.</p>
        <p>No project limit</p>
      </div>
  
      <!-- Premium Plan -->
      <div class="pricing-card bg-white shadow-md rounded-lg p-4 mb-6">
        <h2 class="text-2xl font-bold mb-2">Premium Plan</h2>
        <p class="mb-4">12 USD per user per month.</p>
        <p>You love Timekeeping App, and want to be part of the creation and improvement of it,</p>
        <p>by having the possibility to vote on what improvements we should work on next.</p>
      </div>

    </div>
  </template>
  
  <script>
  export default {
    name: "PricingComponent"
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  .pricing-card {
    border: 1px solid #e5e7eb;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>
  