import axios from 'axios';

const token = localStorage.getItem('token');
const baseURL = process.env.VUE_APP_API_BASE_URL || '/api';

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    authorization: token ? `Bearer ${token}` : ''
  }
});

export default axiosInstance;
