<!-- src/components/FeaturesStatement.vue -->
<template>
  <div class="container mx-auto p-6">
    <h1 class="text-2xl font-bold mb-4">Biz Timesheet Features</h1>
    <section class="mb-4">
      <h2 class="text-xl font-semibold">Time Tracking with Precision</h2>
      <p>Record your work hours effortlessly. Biz Timesheet allows you to track start and end times and log lunch breaks for accurate timesheets.</p>
    </section>
    <section class="mb-4">
      <h2 class="text-xl font-semibold">Project Management</h2>
      <p>Create and manage projects with ease. Organize time entries by project, allowing for clearer reporting and streamlined workflow management.</p>
    </section>
    <section class="mb-4">
      <h2 class="text-xl font-semibold">PDF Export for Timesheets</h2>
      <p>Generate and export your timesheets as PDF files, perfect for record-keeping, invoicing, and easy sharing.</p>
    </section>
    <section class="mb-4">
      <h2 class="text-xl font-semibold">Automatic Holiday Recognition</h2>
      <p>Biz Timesheet automatically detects and applies holidays based on your location to avoid manual adjustments.</p>
    </section>
    <section class="mb-4">
      <h2 class="text-xl font-semibold">Flexible Project Limits</h2>
      <p>Free users can create up to two projects, while premium users have expanded capabilities.</p>
    </section>
    <button @click="$emit('close')" class="text-blue-500 mt-4">Close</button>
  </div>
</template>

<script>
export default {
  name: 'FeaturesStatement'
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
