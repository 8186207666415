<template>
  <div class="bg-blue-500 text-white p-4 shadow-md fixed top-0 inset-x-0 z-10">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Left Side: Logo and App Name -->
      <div class="flex items-center space-x-4">
        <img src="../assets/BizTimesheet_logo_200x200.png" alt="BizTimesheet Logo" class="w-10 h-10" />
        <h1 class="text-xl font-bold">Biz TimeSheet</h1>
        <button @click="$emit('showPricing')" class="bg-white text-blue-500 px-4 py-2 rounded">Pricing</button>
      </div>

      <!-- Right Side: Login, Register, Logout Buttons, Patreon Status, and Support Link -->
      <div class="space-x-4 flex items-center">
        <button v-if="!isLoggedIn" @click="showLogin" class="bg-white text-blue-500 px-4 py-2 rounded">Login</button>
        <button v-if="!isLoggedIn" @click="showRegister" class="bg-white text-blue-500 px-4 py-2 rounded">Register</button>
        <button v-if="isLoggedIn" @click="logout" class="bg-white text-blue-500 px-4 py-2 rounded">Logout</button>

        <!-- Display Patreon status if logged in -->
        <span v-if="isLoggedIn && membershipStatus === 'Patreon Member'" class="text-white">
          Patreon: {{ membershipStatus }}
        </span>
        <span v-if="isLoggedIn && membershipStatus === 'None'" class="text-white">
          Not a Patreon member
        </span>
        <span v-if="isLoggedIn && membershipStatus === 'Unknown'" class="text-white">
          Patreon status: Unknown
        </span>

        <!-- Display "Support us on Patreon" button when not a member or not logged in -->
        <a 
          v-if="!isLoggedIn || (isLoggedIn && membershipStatus === 'None')" 
          href="https://www.patreon.com/c/BizTimeSheet" 
          target="_blank" 
          class="flex items-center bg-red-500 text-white px-4 py-2 rounded shadow-md hover:bg-red-600 transition duration-200"
          style="background-color: #FF424D;">
          <img src="../assets/patreon-logo.png" alt="Patreon Logo" class="w-4 h-4 mr-2" /> 
          Support us on Patreon
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import axios from '../axios-config';

export default {
  name: "NavBar",
  data() {
    return {
      membershipStatus: null, // Store Patreon membership status
    };
  },
  computed: {
    isLoggedIn() {
      return this.$parent.$data.loggedin; // Assuming parent component manages logged-in state
    }
  },
  methods: {
    showLogin() {
      this.$emit('toggleLogin'); // Emits event to parent component to show login modal
    },
    showRegister() {
      this.$emit('toggleRegister'); // Emits event to show register modal
    },
    async logout() {
      await this.$emit('logout'); // Emits logout event to parent component
      this.membershipStatus = null; // Reset membership status on logout
    },
    async fetchMembershipStatus() {
      const token = localStorage.getItem('token');
      try {
        // Use axios to make the HTTP request
        const response = await axios.get('user/membership', {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.membershipStatus = response.data.membershipStatus || 'None'; // Assign membership status
      } catch (error) {
        console.error('Error fetching Patreon membership status:', error);
        this.membershipStatus = 'Unknown'; // Handle errors by setting status to 'Unknown'
      }
    }
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.fetchMembershipStatus(); // Fetch membership status when user logs in
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.fetchMembershipStatus(); // Fetch Patreon membership status if the user is logged in on mount
    }
  }
};
</script>

<style scoped>
/* Additional styles for better presentation */
a {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
}
</style>
