<template>
  <div id="app" class="container mx-auto p-6 relative min-h-screen flex flex-col">
    <!-- NavBar Component with fixed positioning and event for showing pricing -->
    <NavBar 
      @toggleLogin="toggleLogin" 
      @toggleRegister="toggleRegister" 
      @showPricing="togglePricingModal" 
      @logout="logout"
      class="fixed top-0 left-0 right-0 z-10" 
    />

    <!-- Main content area with adjusted top padding to avoid overlapping with NavBar -->
    <div class="flex-grow mt-8 pt-8">
      <div v-if="!token">
        <div class="relative">
          <SalesPitch />

          <!-- Login and Register Forms -->
          <div v-if="showLogin" class="absolute top-16 right-0 bg-white p-6 shadow-lg rounded">
            <AuthComponent @login="handleLogin" :showForm="showLogin" formType="login" />
            <button @click="showForgotPassword = true; showLogin = false;" class="text-blue-500 mt-2">Forgot Password?</button>
          </div>
          <div v-if="showRegister" class="absolute top-16 right-0 bg-white p-6 shadow-lg rounded">
            <AuthComponent @login="handleLogin" :showForm="showRegister" formType="register" />
          </div>

          <!-- Forgot Password Component -->
          <div v-if="showForgotPassword" class="absolute top-16 right-0 bg-white p-6 shadow-lg rounded">
            <ForgotPasswordComponent :visible="showForgotPassword" @close="showForgotPassword = false" />
            <button @click="showForgotPassword = false; showLogin = true;" class="text-blue-500 mt-2">Back to Login</button>
          </div>

          <!-- Reset Password Component -->
          <div v-if="showResetPassword" class="absolute top-16 right-0 bg-white p-6 shadow-lg rounded">
            <ResetPasswordComponent :visible="showResetPassword" :token="resetToken" @close="showResetPassword = false" />
          </div>
        </div>
      </div>

      <!-- Logged in view -->
      <div v-else>
        <!-- Menu is placed above TimeTracker to control actions -->
        <Menu
          :selectedMonth="selectedMonth"
          :months="months"
          :isAdmin="isAdmin"
          @save-time-entries="saveTimeEntries"
          @download-pdf="downloadPDF"
          @edit-profile="editProfile"
          @add-users="showAddUsersModal = true"
          @open-dashboard="goToAdminDashboard"
        />

        <!-- Pass isAdmin prop to TimeTracker -->
        <TimeTracker :isAdmin="isAdmin" />
      </div>
    </div>

    <!-- Pricing Modal -->
    <Modal v-if="showPricingModal" title="Pricing Plans" @close="togglePricingModal">
      <PricingComponent />
    </Modal>

    <!-- Privacy Statement Modal -->
    <div v-if="showPrivacyStatement" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-20">
      <PrivacyStatement @close="showPrivacyStatement = false" />
    </div>

    <!-- Terms of Service Modal -->
    <div v-if="showTermsOfService" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-20">
      <TermsOfService @close="showTermsOfService = false" />
    </div>

    <!-- Features Modal -->
    <div v-if="showFeatures" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-20">
      <FeaturesStatement @close="toggleFeaturesModal" />
    </div>

    <!-- Footer: Only show if no modal is active -->
    <AppFooter 
      v-if="!showPricingModal && !showPrivacyStatement && !showTermsOfService && !showAddUsersModal && !showFeatures" 
      @showPrivacy="showPrivacyStatement = true" 
      @showTerms="showTermsOfService = true" 
      @showFeatures="toggleFeaturesModal" 
    />
  </div>
</template>

<script>
import SalesPitch from './components/SalesPitch.vue';
import AuthComponent from './components/AuthComponent.vue';
import TimeTracker from './components/TimeTracker.vue';
import ForgotPasswordComponent from './components/ForgotPasswordComponent.vue';
import ResetPasswordComponent from './components/ResetPasswordComponent.vue';
import AppFooter from './components/AppFooter.vue';
import PrivacyStatement from './components/PrivacyStatement.vue';
import TermsOfService from './components/TermsOfService.vue';
import PricingComponent from './components/PricingComponent.vue';
import NavBar from './components/NavBar.vue';
import Modal from './components/Modal.vue';
import FeaturesStatement from './components/FeaturesStatement.vue';

export default {
  components: {
    SalesPitch,
    AuthComponent,
    TimeTracker,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AppFooter,
    PrivacyStatement,
    TermsOfService,
    PricingComponent,
    NavBar,
    Modal,
    FeaturesStatement, // Register FeaturesStatement component
  },
  data() {
    return {
      token: localStorage.getItem('token') || '',
      showLogin: false,
      showRegister: false,
      showForgotPassword: false,
      showResetPassword: false,
      showAddUsersModal: false,
      showPricingModal: false, // Data property to control Pricing Modal visibility
      showPrivacyStatement: false,
      showTermsOfService: false,
      showFeatures: false, // Data property to control Features Modal visibility
      resetToken: '',
      selectedMonth: new Date().getMonth() + 1,
      months: [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 },
      ],
      role: localStorage.getItem('role') || '',
      loggedin: !!localStorage.getItem('token') || '',
    };
  },
  computed: {
    isAdmin() {
      return this.role === 'admin'; // Use this computed property to check if the user is admin
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      this.resetToken = token;
      this.showResetPassword = true;
    }

    // Set role from localStorage if available
    this.role = localStorage.getItem('role') || '';
  },
  methods: {
    handleLogin(token, role) {
      this.token = token;
      this.role = role; // Assign role from login response
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      this.loggedin = true;
    },
    logout() {
      this.token = '';
      this.role = ''; // Clear role on logout
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      this.loggedin = false;
    },
    toggleLogin() {
      this.showLogin = !this.showLogin;
      this.showRegister = false;
      this.showForgotPassword = false;
    },
    toggleRegister() {
      this.showRegister = !this.showRegister;
      this.showLogin = false;
      this.showForgotPassword = false;
    },
    togglePricingModal() {
      this.showPricingModal = !this.showPricingModal;
    },
    togglePrivacyModal() {
      this.showPrivacyStatement = !this.showPrivacyStatement;
    },
    toggleTermsModal() {
      this.showTermsOfService = !this.showTermsOfService;
    },
    toggleFeaturesModal() {
      this.showFeatures = !this.showFeatures;
    },
    goToAdminDashboard() {
      this.$router.push({ name: 'AdminDashboard' });
    },
    setResetToken(token) {
      this.resetToken = token;
      this.showResetPassword = true;
      this.showForgotPassword = false;
      this.showLogin = false;
    },
  }
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-grow {
  flex: 1;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
