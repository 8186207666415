<template>
  <div class="max-w-md mx-auto mt-4">
    <!-- Registration Form -->
    <div v-if="formType === 'register'">
      <h2 class="text-xl font-bold mb-4">Register</h2>
      <form @submit.prevent="register" class="mb-4">
        <div class="mb-3">
          <input v-model="registerUsername" class="form-input w-full border rounded px-4 py-2" placeholder="Username" required />
        </div>
        <div class="mb-3">
          <input v-model="registerPassword" type="password" class="form-input w-full border rounded px-4 py-2" placeholder="Password" required />
        </div>
        <div class="mb-3">
          <input v-model="registerEmail" type="email" class="form-input w-full border rounded px-4 py-2" placeholder="Email" required />
        </div>
        <div class="mb-3">
          <input v-model="registerCompany" class="form-input w-full border rounded px-4 py-2" placeholder="Company Name" required />
        </div>
        <div class="mb-3">
          <select v-model="registerCountry" class="form-input w-full border rounded px-4 py-2" required>
            <option value="">Select Country</option>
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div v-if="errorMessage" class="text-red-500 mb-3">{{ errorMessage }}</div>
        <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded w-full" :disabled="loading">
          <span v-if="loading">Registering...</span>
          <span v-else>Register</span>
        </button>
      </form>
    </div>

    <!-- Login Form -->
    <div v-else-if="formType === 'login'">
      <h2 class="text-xl font-bold mb-4">Login</h2>
      <form @submit.prevent="login">
        <div class="mb-3">
          <input v-model="username" class="form-input w-full border rounded px-4 py-2" placeholder="Username" required />
        </div>
        <div class="mb-3">
          <input v-model="password" type="password" class="form-input w-full border rounded px-4 py-2" placeholder="Password" required />
        </div>
        <div class="mb-3">
          <input v-model="company" class="form-input w-full border rounded px-4 py-2" placeholder="Company Name" required />
        </div>
        <div v-if="errorMessage" class="text-red-500 mb-3">{{ errorMessage }}</div>
        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded w-full" :disabled="loading">
          <span v-if="loading">Logging in...</span>
          <span v-else>Login</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../axios-config';

export default {
  name: 'AuthComponent',
  props: {
    showForm: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      registerUsername: '',
      registerPassword: '',
      registerEmail: '',
      registerCompany: '',
      registerCountry: '',
      username: '',
      password: '',
      company: '',
      errorMessage: '',
      loading: false,
      token: localStorage.getItem('token') || '',
      countries: [
        { code: 'US', name: 'United States' },
        { code: 'CA', name: 'Canada' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'AU', name: 'Australia' },
        { code: 'IN', name: 'India' },
        { code: 'DK', name: 'Denmark' },
        // Add more countries as needed
      ]
    };
  },
  methods: {
    async register() {
      this.errorMessage = '';
      this.loading = true;
      try {
        const response = await axiosInstance.post('/auth/register', {
          username: this.registerUsername,
          password: this.registerPassword,
          email: this.registerEmail,
          company_name: this.registerCompany,
          country_fk: this.registerCountry
        });
        alert(response.data.message);
        this.$emit('toggleRegister');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          this.errorMessage = error.response.data.error;
        } else {
          this.errorMessage = 'Registration failed. Please try again.';
        }
        console.error('Registration failed:', error);
      } finally {
        this.loading = false;
      }
    },
    async login() {
      this.errorMessage = '';
      this.loading = true;
      try {
        const response = await axiosInstance.post('/auth/login', {
          username: this.username,
          password: this.password,
          company: this.company
        });

        const { token, role } = response.data;
        this.token = token;
        localStorage.setItem('token', this.token); // Store the token
        localStorage.setItem('role', role); // Store the role
        this.$emit('login', this.token, role); // Emit login event with token and role

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = 'Invalid credentials or company name. Please try again.';
        } else {
          this.errorMessage = 'Login failed. Please try again later.';
        }
        console.error('Login failed:', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.form-input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.bg-green-500 {
  background-color: #38a169;
}
.bg-blue-500 {
  background-color: #4299e1;
}
.text-red-500 {
  color: #f56565;
}
</style>
