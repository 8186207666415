<template>
  <footer class="bg-gray-800 text-white py-4 mt-6">
    <div class="container mx-auto text-center">
      <p>&copy; 2024 Flying Hippo Studio. All rights reserved.</p>
      <div class="mt-2">
        <a href="#" @click.prevent="$emit('showPrivacy')" class="text-blue-400 hover:text-blue-200">Privacy Statement</a> |
        <a href="#" @click.prevent="$emit('showTerms')" class="text-blue-400 hover:text-blue-200">Terms of Service</a> |
        <a href="/featuresStatement" class="text-blue-400 hover:text-blue-200">Features</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
footer {
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
}

a {
  color: #1e90ff;
  text-decoration: none;
  margin: 0 5px;
}

a:hover {
  text-decoration: underline;
}
</style>
