import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: 'G-8C0CTDPMMT' // Replace with your Google Analytics Measurement ID
  }
});

app.mount('#app');
