<template>
  <div class="container mx-auto mt-4">
    <h2 class="text-xl font-semibold mb-4">Edit Your Information</h2>
    <form @submit.prevent="updateUser">
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-700 mb-2">Name:</label>
        <input type="text" id="name" v-model="user.name" class="input-field" />
      </div>
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700 mb-2">Email:</label>
        <input type="email" id="email" v-model="user.email" class="input-field" />
      </div>
      <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-700 mb-2">Password:</label>
        <input type="password" id="password" v-model="user.password" class="input-field" />
      </div>
      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600">Update</button>
    </form>
  </div>
</template>

<script>
import axios from '../axios-config';

export default {
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: ''
      },
      token: localStorage.getItem('token') || ''
    };
  },
  methods: {
    async fetchUserData() {
      try {
        const response = await axios.get('/user/profile', {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.user = response.data;
      } catch (error) {
        console.error("There was an error fetching the user data:", error);
      }
    },
    async updateUser() {
      try {
        const response = await axios.put('/user/update', this.user, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        if (!response.status === 200) {
           alert('Failed to update user information');
        }
      } catch (error) {
        console.error("There was an error updating the user data:", error);
      }
    }
  },
  mounted() {
    this.fetchUserData();
  }
};
</script>

<style scoped>
.input-field {
  @apply w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent;
}
</style>
