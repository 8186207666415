<template>
  <div class="container mx-auto mt-4 px-2 sm:px-4">
    <!-- Menu Actions Component -->
    <MenuActions
      :selected-month="selectedMonth"
      :months="months"
      :isAdmin="isAdmin" 
      @update:selected-month="selectedMonth = $event; createMonth()"
      @save-time-entries="saveTimeEntries"
      @download-pdf="downloadPDF"
      @edit-profile="showEditUserModal = true"
      @create-projects="showCreateProjectsModal = true" 
    />

    <!-- Time Entries Table -->
    <div class="overflow-x-auto mt-4">
      <table class="min-w-full bg-white">
        <thead class="bg-gray-800 text-white">
          <tr>
            <th class="w-1/6 py-2 text-xs sm:text-sm">Date</th>
            <th class="w-1/6 py-2 text-xs sm:text-sm">Start Time</th>
            <th class="w-1/6 py-2 text-xs sm:text-sm">End Time</th>
            <th class="w-1/6 py-2 text-xs sm:text-sm">Lunch Break (min)</th>
            <th class="w-1/6 py-2 text-xs sm:text-sm">Total (hours)</th>
            <th class="w-1/6 py-2 text-xs sm:text-sm">Project</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(entry, index) in timeEntries"
            :key="entry.id || entry.date" 
            :class="{ 
              'bg-red-100': entry.isHoliday,
              'bg-yellow-100': modifiedEntries[entry.id || entry.date]
            }"
            class="bg-gray-100 border-b"
          >
            <!-- Date Column -->
            <td class="py-2 px-2 sm:px-4 text-xs sm:text-sm">{{ entry.date }}</td>
            
            <!-- Start Time Column -->
            <td class="py-2 px-2 sm:px-4">
              <input
                type="time"
                v-model="entry.startTime"
                @input="markAsModified(entry.id || entry.date)"
                @change="calculateTotal(index)"
                class="border rounded px-2 py-1 text-xs sm:text-sm w-full"
              />
            </td>
            
            <!-- End Time Column -->
            <td class="py-2 px-2 sm:px-4">
              <input
                type="time"
                v-model="entry.endTime"
                @input="markAsModified(entry.id || entry.date)"
                @change="calculateTotal(index)"
                class="border rounded px-2 py-1 text-xs sm:text-sm w-full"
              />
            </td>
            
            <!-- Lunch Break Column -->
            <td class="py-2 px-2 sm:px-4">
              <input
                type="number"
                min="0"
                v-model.number="entry.lunch"
                @input="markAsModified(entry.id || entry.date)"
                @change="calculateTotal(index)"
                class="border rounded px-2 py-1 text-xs sm:text-sm w-full"
              />
            </td>
            
            <!-- Total Hours Column -->
            <td class="py-2 px-2 sm:px-4 text-xs sm:text-sm">{{ entry.total }}</td>
            
            <!-- Project Column -->
            <td class="py-2 px-2 sm:px-4">
              <select
                v-model="entry.projects_fk"
                @change="onProjectChange($event, index)"
                @input="markAsModified(entry.id || entry.date)"
                class="border rounded px-2 py-1 text-xs sm:text-sm w-full"
              >
                <option :value="null">No Project</option> <!-- 'No Project' option -->
                <option
                  v-for="project in projects"
                  :key="project.id"
                  :value="project.id"
                >
                  {{ project.project_name }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Edit User Modal -->
    <Modal v-if="showEditUserModal" @close="showEditUserModal = false" title="Edit Your Information">
      <EditUser @update-success="showEditUserModal = false" />
    </Modal>

    <!-- Create Project Modal -->
    <Modal v-if="showCreateProjectsModal" @close="showCreateProjectsModal = false" title="Create Project">
      <CreateProject @projectCreated="handleProjectCreated" @close="showCreateProjectsModal = false" />
    </Modal>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from '../axios-config';
import EditUser from './EditUser.vue';
import Modal from './Modal.vue';
import MenuActions from './Menu.vue';
import CreateProject from './CreateProject.vue';

export default {
  name: 'TimeTracker',
  components: {
    Modal,
    EditUser,
    MenuActions,
    CreateProject,
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedMonth: new Date().getMonth() + 1, // 1-12
      months: [
        'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October',
        'November', 'December',
      ],
      projects: [],
      timeEntries: [],
      token: localStorage.getItem('token') || '',
      showEditUserModal: false,
      showAddUsersModal: false,
      showAdminDashboardModal: false,
      showCreateProjectsModal: false,
      modifiedEntries: {}, // Object to track modified entries by id or date
    };
  },
  methods: {
    /**
     * Fetch and initialize time entries for the selected month.
     */
    async createMonth() {
      const year = new Date().getFullYear();
      const monthIndex = this.selectedMonth;
  
      this.timeEntries = [];
  
      try {
        // Fetch saved time entries from backend
        const response = await axios.get('/get-entries', {
          headers: { Authorization: `Bearer ${this.token}` },
          params: {
            year,
            month: monthIndex.toString().padStart(2, '0'),
          },
        });
  
        const savedEntries = response.data;
  
        // Fetch public holidays for the month
        const holidaysResponse = await axios.get('/public-holidays', {
          headers: { Authorization: `Bearer ${this.token}` },
          params: {
            year,
            month: monthIndex.toString().padStart(2, '0'),
          },
        });
  
        const holidays = holidaysResponse.data;
  
        const daysInMonth = new Date(year, monthIndex, 0).getDate();
  
        // Initialize timeEntries with data and default values
        this.timeEntries = Array.from({ length: daysInMonth }, (_, i) => {
          const dateObj = new Date(year, monthIndex - 1, i + 1);
          const yearPart = dateObj.getFullYear();
          const monthPart = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const dayPart = String(dateObj.getDate()).padStart(2, '0');
          const date = `${yearPart}-${monthPart}-${dayPart}`; // 'YYYY-MM-DD'
  
          // Check if the current date is a holiday
          const isHoliday = holidays.some((holiday) => {
            const holidayDateObj = new Date(holiday.date);
            const holidayYear = holidayDateObj.getFullYear();
            const holidayMonth = String(holidayDateObj.getMonth() + 1).padStart(2, '0');
            const holidayDay = String(holidayDateObj.getDate()).padStart(2, '0');
            const holidayDate = `${holidayYear}-${holidayMonth}-${holidayDay}`;
            return holidayDate === date;
          });
  
          // Find saved entry for the current date
          const savedEntry = savedEntries.find((entry) => entry.Date === date);
  
          // Map saved entry to the timeEntries format
          const entryObj = savedEntry
            ? {
                id: savedEntry.id, // Ensure id is included for unique identification
                date: savedEntry.Date,
                startTime: savedEntry.StartTime ? savedEntry.StartTime.slice(0, 5) : '',
                endTime: savedEntry.EndTime ? savedEntry.EndTime.slice(0, 5) : '',
                lunch: savedEntry.LunchTime || 0,
                total: savedEntry.Total || 0,
                isHoliday,
                projects_fk: savedEntry.projects_fk ? parseInt(savedEntry.projects_fk, 10) : null,
                project_name: savedEntry.project_name,
              }
            : { 
                date, 
                startTime: '', 
                endTime: '', 
                lunch: 0, 
                total: 0, 
                isHoliday, 
                projects_fk: null 
              };
  
          return entryObj;
        });
  
      } catch (error) {
        console.error('Error fetching saved entries or holidays:', error);
        alert('Failed to load time entries. Please try again later.');
      }
    },
    
    /**
     * Save or update modified time entries with required fields.
     */
    async saveTimeEntries() {
      
      // Filter out entries that have not been modified or lack required fields
      const entriesToSave = this.timeEntries.filter(entry => {
        return (this.modifiedEntries[entry.id || entry.date]) && (entry.startTime && entry.endTime);
      });

      if (entriesToSave.length === 0) {
        return;
      }

      try {
        const response = await axios.post('/add-entry', entriesToSave, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        if (response.data.status === 'OK') {
          this.modifiedEntries = {}; // Clear modified entries
          this.createMonth(); // Refresh entries after successful save
        } else {
          alert('Failed to submit entries');
        }
      } catch (error) {
        console.error('Error submitting entries:', error);
        if (error.response && error.response.data && error.response.data.error) {
          alert(`Error: ${error.response.data.error}`);
        } else {
          alert('An unexpected error occurred while submitting entries.');
        }
      }
    },
    
    /**
     * Download the current month's timesheet as a PDF.
     */
    downloadPDF() {
      const doc = new jsPDF();
      doc.text('Timesheet', 20, 10);
  
      const columns = ['Date', 'Start Time', 'End Time', 'Lunch Break (min)', 'Total (hours)', 'Project'];
      const rows = this.timeEntries.map((entry) => [
        entry.date,
        entry.startTime || '',
        entry.endTime || '',
        entry.lunch,
        entry.total,
        entry.project_name
      ]);
  
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });
  
      doc.save(`Timesheet_${this.months[this.selectedMonth - 1]}_${new Date().getFullYear()}.pdf`);
    },
    
    /**
     * Calculate total hours based on start time, end time, and lunch break.
     * @param {Number} index - Index of the entry in timeEntries array.
     */
    calculateTotal(index) {
      const entry = this.timeEntries[index];
      if (entry.startTime && entry.endTime) {
        const start = new Date(`1970-01-01T${entry.startTime}:00`);
        const end = new Date(`1970-01-01T${entry.endTime}:00`);
        const totalMs = end - start - entry.lunch * 60000; // Deduct lunch break in minutes
        entry.total = (totalMs / 3600000).toFixed(2); // Convert to hours and round to 2 decimals
      } else {
        entry.total = 0;
      }
    },
    
    /**
     * Fetch available projects from the backend.
     */
    async getProjects() {
      try {
        const response = await axios.get('/get-projects', {
          headers: { Authorization: `Bearer ${this.token}` },
        });

        this.projects = response.data.projects;
      } catch (error) {
        console.error('Error fetching projects:', error);
        alert('Failed to load projects. Please try again later.');
      }
    },
    
    /**
     * Handle changes in the project selection dropdown.
     * @param {Event} event - The change event.
     * @param {Number} index - Index of the entry in timeEntries array.
     */
    onProjectChange(event, index) {
      const entry = this.timeEntries[index];
      const value = event.target.value;
      entry.projects_fk = value ? parseInt(value, 10) : null; // Set to null if 'No Project' is selected
    },
    
    /**
     * Mark an entry as modified to track changes.
     * @param {String|Number} entryKey - The unique key of the entry (id or date).
     */
    markAsModified(entryKey) {
      // Only mark as modified if required fields are present
      const entry = this.timeEntries.find(e => e.id === entryKey || e.date === entryKey);
      if (entry.startTime && entry.endTime && entry.date) {
        this.modifiedEntries[entryKey] = true;
      } else {
        // If required fields are missing, remove the entry from modifiedEntries
        delete this.modifiedEntries[entryKey];
      }
    },
    
    /**
     * Handle the event when a new project is created.
     */
    handleProjectCreated() {
      this.showCreateProjectsModal = false;
      this.getProjects(); // Re-fetch projects to include the new one
    },
  },
  mounted() {
    this.getProjects().then(() => {
      this.createMonth();
    });
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}
.bg-red-100 {
  background-color: #ffe5e5;
}
.bg-yellow-100 {
  background-color: #fff3cd;
}
.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.dropdown {
  width: 100%;
}
</style>
