<!-- src/components/Modal.vue -->
<template>
  <div class="modal-overlay fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-20">
    <div class="modal-container bg-white rounded-lg shadow-lg w-3/4 max-w-lg max-h-full">
      <div class="modal-header px-4 py-2 flex justify-between items-center border-b">
        <h2 class="text-lg font-semibold">{{ title }}</h2>
        <button @click="$emit('close')" class="text-gray-700 hover:text-gray-900">&times;</button>
      </div>
      <div class="modal-body p-4 overflow-y-auto max-h-[80vh]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: 'Modal Title',
    },
  },
};
</script>

<style scoped>
/* Modal overlay covering the entire screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(75, 85, 99, 0.75); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20; /* Ensure it stays above other content */
}

/* Modal container styling */
.modal-container {
  width: 75%;
  max-width: 600px;
  max-height: 80vh; /* Sets a max height for the modal */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

/* Modal body to handle overflow content */
.modal-body {
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: calc(80vh - 50px); /* Ensures the modal header is accounted for */
}
</style>
