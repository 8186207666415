<template>
  <div class="cta-container">
    <header>
      <h1>Welcome to Biz Timesheet – Simple, Secure Time Tracking with Flexible Project Management!</h1>
    </header>

    <section>
      <p>
        Keeping track of work hours and project time should be simple, especially with changing compliance needs.
        Biz Timesheet brings you an easy-to-use time-tracking tool that respects your data security, while giving you practical project
        and time management features.
      </p>
    </section>

    <section>
      <h2>Essential Features for Streamlined Time Tracking:</h2>
      <ul>
        <li><strong>Create and Edit Entries:</strong> Effortlessly log work hours, including lunch breaks, and adjust entries as needed.</li>
        <li><strong>Holiday Recognition by Country:</strong> See public holidays automatically based on your location, making time tracking more accurate and convenient.</li>
        <li><strong>Export to PDF:</strong> Generate clean, professional timesheets for record-keeping or reporting.</li>
        <li><strong>Manage Projects Your Way:</strong> Free users can create and manage up to two projects, with the ability to delete projects that don’t contain any logged time.</li>
        <li><strong>Unlock More with Patreon:</strong> Patreon members can add unlimited projects and gain input into the product's development.</li>
      </ul>
    </section>

    <section>
      <h2>Why Choose Biz Timesheet?</h2>
      <ul>
        <li><strong>GDPR-Compliant and Secure:</strong> Your data privacy is a top priority, with full GDPR compliance built-in.</li>
        <li><strong>Personalize Your Profile:</strong> Easily update your user details for accurate record-keeping.</li>
        <li><strong>Effortless for Teams and Individuals:</strong> From freelancers to growing teams, Biz Timesheet adapts to your project and time-tracking needs.</li>
        <li><strong>Have a Say in Future Features:</strong> As a Patreon member, your feedback directly influences upcoming features and improvements.</li>
      </ul>
    </section>

    <footer>
      <h2>Take Control of Your Time, Simplify Your Workflow!</h2>
      <p>
        Biz Timesheet provides the essential tools for effective time and project management, whether you're tracking time
        for one project or managing several. Join today and see how easy and flexible time tracking can be!
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SalesPitch",
};
</script>

<style scoped>
.cta-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}
h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 15px;
}
h2 {
  color: #444;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}
p {
  color: #555;
  line-height: 1.6;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 10px 0;
}
strong {
  color: #000;
}
</style>
