<template>
  <div class="create-project-container">
    <!-- Display warning if the user cannot create more projects -->
    <div v-if="reachedProjectLimit" class="error">
      You can only create 2 projects. Upgrade your Patreon membership to create more.
    </div>

    <!-- Project Creation Form (disabled if limit reached) -->
    <form @submit.prevent="createProject" v-if="!reachedProjectLimit">
      <input v-model="projectName" placeholder="Project Name" required class="input-field" />
      <textarea v-model="description" placeholder="Project Description" class="input-field"></textarea>
      <div class="modal-actions">
        <button type="submit" class="btn-primary">Create Project</button>
        <button @click="closeModal" type="button" class="btn-secondary">Cancel</button>
      </div>
    </form>

    <!-- Display Existing Projects -->
    <div class="existing-projects" v-if="projects.length">
      <h3>Existing Projects</h3>
      <ul>
        <li v-for="project in projects" :key="project.id" class="project-item">
          <div><button @click="deleteProject(project.id)" type="button" class="btn-secondary">Delete</button>{{ project.project_name }} - {{ project.description }}</div>
        </li>
      </ul>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="loading">
      Loading projects...
    </div>

    <!-- Error Message -->
    <div v-if="error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import axiosInstance from '../axios-config';

export default {
  name: 'CreateProject',
  data() {
    return {
      projectName: '',
      description: '',
      projects: [],      // Store fetched projects
      isLoading: false,  // Handle loading state
      error: null,       // Handle errors
      reachedProjectLimit: false,  // Track if user reached project limit
      projectCount: 0,   // Store number of projects user created
      patreonMembership: '',  // Store the Patreon membership level
    };
  },
  methods: {
    async createProject() {
      try {
        const response = await axiosInstance.post('/create-project', {
          project_name: this.projectName,
          description: this.description
        });

        if (response.status === 200 || response.status === 201) {
          this.$emit('projectCreated'); // Notify parent of project creation
          this.closeModal(); // Close modal after successful creation
          this.fetchProjects(); // Refresh the projects list
        } 
      } catch (error) {
        console.error('Error creating project:', error.response ? error.response.data : error.message);
        alert('Error creating project: ' + (error.response && error.response.data ? error.response.data.error : error.message));
      }
    },
    async fetchProjects() {
      this.projects = [];
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('token'); // Retrieve the token from localStorage

      if (!token) {
        this.error = 'No authentication token found';
        return;
      }

      try {
        const response = await axiosInstance.get('/get-projects', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          }
        });
        this.projects = response.data.projects;
        this.projectCount = response.data.projectCount;  // Get the user's project count
        this.patreonMembership = response.data.patreonMembership;  // Get the user's Patreon membership level

        // Check if user has reached project creation limit
        if (this.projectCount >= 2 && this.patreonMembership === 'None') {
          this.reachedProjectLimit = true;  // Disable project creation if limit is reached
        }

      } catch (error) {
        console.error('Error fetching projects:', error.response ? error.response.data : error.message);
        this.error = 'Failed to load projects.';
      } finally {
        this.isLoading = false;
      }
    },
    async deleteProject(project_id) {
      const token = localStorage.getItem('token');

      if (!token) {
        this.error = 'No authentication token found';
        return;
      }

       try {
        const response = await axiosInstance.post('/delete-project', {
          project_fk: project_id
        });

        if (response.status === 200 || response.status === 201) {
          alert('Project deleted successfully!');
          this.fetchProjects(); // Refresh the projects list
        } else {
          alert(response.message);
        }
      } catch (error) {
        console.error('Error deleting project:', error.response ? error.response.data : error.message);
        alert('Error deleting project: ' + (error.response && error.response.data ? error.response.data.error : error.message));
      }
    },
    closeModal() {
      this.$emit('close');
    }
  },
  mounted() {
    this.fetchProjects(); // Fetch projects when component is mounted
  }
};
</script>

<style scoped>
.input-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn-primary {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #f44336;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error {
  color: red;
  margin-bottom: 15px;
}

.loading {
  font-size: 16px;
}
</style>
