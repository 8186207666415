<template>
  <div v-if="visible" class="reset-password">
    <div class="modal-header">
      <h2>Reset Password</h2>
      <!-- Close button to emit a close event -->
      <button class="close-button" @click="$emit('close')">×</button>
    </div>
    <form @submit.prevent="resetPassword">
      <input
        type="password"
        v-model="newPassword"
        placeholder="New Password"
        required
      />
      <input
        type="password"
        v-model="confirmPassword"
        placeholder="Confirm Password"
        required
      />
      <button type="submit">Reset Password</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    visible: Boolean,
    token: String
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      message: ""
    };
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.message = "Passwords do not match!";
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/auth/reset-password`, {
          token: this.token,
          newPassword: this.newPassword
        });
        this.message = response.data.message;
      } catch (error) {
        this.message = "Error resetting password. Please try again.";
        console.error('Error details:', error.response.data);
      }
    }
  }
};
</script>

<style scoped>
.reset-password {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Allow positioning of the close button */
  background-color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Space between header and form */
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

input,
button {
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
}

button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}
</style>
