<template>
  <div class="flex justify-between items-center mb-4">
    <select :value="selectedMonth" @change="handleMonthChange" class="border rounded px-4 py-2">
      <option v-for="(month, index) in months" :key="index" :value="index + 1">
        {{ month }}
      </option>
    </select>
    <div>
      <button @click="$emit('saveTimeEntries')" class="bg-blue-500 text-white px-4 py-2 rounded mr-2">Save</button>
      <button @click="$emit('download-pdf')" class="bg-green-500 text-white px-4 py-2 rounded mr-2">Export to PDF</button>
      <button @click="$emit('edit-profile')" class="bg-yellow-500 text-white px-4 py-2 rounded">Edit Profile</button>

      <button v-if="isAdmin" @click="createProjects" class="bg-blue-500 text-white px-4 py-2 rounded ml-2">
        Create Projects
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuActions',
  props: {
    selectedMonth: Number,
    months: Array,
    isAdmin: Boolean, // isAdmin passed to check admin status
  },
  methods: {
    createProjects() {
      this.$emit('create-projects');
    },
    handleMonthChange(event) {
      const newValue = parseInt(event.target.value, 10);
      this.$emit('update:selected-month', newValue); // Emit the change event
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to the menu component if needed */
</style>
