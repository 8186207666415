<!-- ForgotPasswordComponent.vue -->
<template>
  <div v-if="visible" class="forgot-password">
    <h2>Forgot Password</h2>
    <form @submit.prevent="sendResetLink">
      <input
        type="email"
        v-model="email"
        placeholder="Enter your email"
        required
      />
      <button type="submit">Send Reset Link</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    visible: Boolean
  },
  data() {
    return {
      email: "",
      message: ""
    };
  },
  methods: {
    async sendResetLink() {
      try {
        // Use environment variable for the API base URL
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/auth/forgot-password`, {
          email: this.email
        });
        this.message = response.data.message;
      } catch (error) {
        this.message = "Error sending reset link. Please try again.";
      }
    }
  }
};
</script>

<style scoped>
.forgot-password {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
form {
  display: flex;
  flex-direction: column;
}
input,
button {
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
}
button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}
</style>
