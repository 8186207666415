<!-- src/components/PrivacyStatement.vue -->
<template>
    <div class="container mx-auto p-6">
      <h1 class="text-2xl font-bold mb-4">Privacy Statement</h1>
      <p class="mb-4">
        Your privacy is important to us. This privacy statement explains the personal data our application processes, how we process it, and for what purposes.
      </p>
      <h2 class="text-xl font-bold mb-2">Information We Collect</h2>
      <p class="mb-4">
        We collect information that you provide directly to us when you use our services, such as when you create an account, use our application, or communicate with us.
      </p>
      <h2 class="text-xl font-bold mb-2">How We Use Information</h2>
      <p class="mb-4">
        We use the information we collect to provide, maintain, and improve our services, to develop new services, and to protect our application and our users.
      </p>
      <!-- Add more sections as necessary -->
      <button @click="$emit('close')" class="text-blue-500 mt-4">Close</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyStatement'
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>
  